import { IDataCard } from '@components/card-main';
import { IconsNames } from '@components/Icon';
import { IOptionSelect } from '@components/input';
export * from './CarouselTestimony';

/**
 * This interface describes that properties the carousel component receives
 * @typeParam dataCarousel: ICarouselAboutUs - Optional prop for data carousel
 * @typeParam onClickCarousel: (data: IDataCarousel[]) => void - Optional click in the card
 * @typeParam sizeScreen: number - Optional value size screen
 */
export interface ICarouselAboutUsData {
    dataCarousel: ICarouselAboutUs[];
    onClickCarousel: (data: IDataCarousel[]) => void;
    sizeScreen: number;
}

/**
 * This interface describes that properties the carousel component receives
 * @typeParam name: string - Optional prop for name group
 * @typeParam data: IDataCarousel[] - Require data carousel
 * @typeParam idGroup: string - Require id group members carousel
 */
export interface ICarouselAboutUs {
    name?: string;
    data: IDataCarousel[];
    idGroup: string;
}

/**
 * This interface describes that data the carousel component receives
 * @typeParam icon: string - Optional icon name member
 * @typeParam nameMember: string - Optional name member
 * @typeParam position: string - Optional position member
 * @typeParam idMember: string - Optional id member
 */
export interface IDataCarousel {
    icon: IconsNames;
    nameMember?: string;
    position?: string;
    idMember?: string;
}

/**
 * This interface describes that properties the modal component receives
 * @typeParam dataModal: IDataCarousel[] - Optional data modal
 * @typeParam showModal: boolean - Require state modal
 * @typeParam setShowModal: () => void - Require function state modal
 */
export interface IModalAboutUs {
    dataModal?: IDataCarousel[];
    showModal: boolean;
    setShowModal: () => void;
}

/**
 * This interface describes that properties the form props
 * @typeParam handleChange: (data: React.ChangeEvent<HTMLInputElement>) => void - Require handle form
 * @typeParam handleChangeSelect?: (name:string, data: IOptionSelect) => void - Optional require handle select form
 * @typeParam dataForm: IDataForm - Require data form
 * @typeParam onClickSave: () => void - Optional  click save
 * @typeParam activeValidate: boolean - Optional active send
 * @typeParam checkDataProcessing: boolean - Optional accept data processing
 * @typeParam handleDataProcessing: () => void - Optional function data processing
 */
export interface IForm {
    handleChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeSelect?: (name: string, data: IOptionSelect) => void;
    dataForm: IDataForm;
    onClickSave?: () => void;
    activeValidate?: boolean;
    checkDataProcessing?: boolean;
    handleDataProcessing?: () => void;
}

/**
 * This interface describes that data the form
 * @typeParam name_surname: string - Require name form
 * @typeParam email: string - Require email form
 * @typeParam prefix: string - Require prefix form
 * @typeParam phone: string - Require phone form
 * @typeParam company_name: string - Require company form
 * @typeParam affair: string - Require affair form
 * @typeParam description: string - Require doubts form
 * @typeParam dataProcessing: boolean - Optional accept data processing
 */
export interface IDataForm {
    name_surname: string;
    email: string;
    prefix: string;
    phone: string;
    company_name: string;
    affair: string;
    description: string;
    dataProcessing: boolean;
}

/**
 * This interface describes that data the form
 * @typeParam id: number - Require id prefix country
 * @typeParam country: string - Require country option
 * @typeParam countryCode: string - Require country code option
 */
export interface ICountryOption {
    id: number;
    country: string;
    countryCode: string;
}

/**
 * This interface describes that properties the carousel testimony component receives
 * @typeParam dataCarousel: IDataCard[] - Optional prop for data carousel testimony
 * @typeParam sizeScreen: number - Optional value size screen
 */
export interface ICarouselTestimony {
    dataCarousel: IDataCard[];
    sizeScreen: number;
}

/**
 * Initial data prefix
 */
const initialDataPrefix = {
    id: 46,
    country: 'Colombia',
    countryCode: '+57',
};

/**
 * This initial data form contact-us
 */
export const INITIAL_DATA_FORM = {
    name_surname: '',
    email: '',
    prefix: initialDataPrefix.countryCode,
    phone: '',
    company_name: '',
    affair: '',
    description: '',
    type: 'CCXC',
    dataProcessing: false,
};

export const TYPE_INPUT_FORM = {
    PREFIX: 'prefix',
    AFFAIR: 'affair',
}

/**
 * This data option contact us
 */
export const optionSelectForm = [
    { key: '1', value: 'Asuntos administrativos' },
    { key: '2', value: 'Asuntos jurídicos' },
    { key: '3', value: 'Diseño UI/UX' },
    { key: '4', value: 'Desarrollo de software' },
    { key: '5', value: 'Fotografía de producto' },
    { key: '6', value: 'Identidad de marca' },
    { key: '7', value: 'Planeación estratégica' },
    { key: '8', value: 'Planeación financiera' },
    { key: '9', value: 'Productividad y competitividad' },
    { key: '10', value: 'Publicidad y mercadeo' },
    { key: '11', value: 'Recursos humanos y control interno' },
    { key: '12', value: 'Tecnología' },
    { key: '13', value: 'Otro' },
];

/**
 * Max lenght input phone
 */
export const MAX_LENGTH_PHONE = 13;

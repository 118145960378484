import axios from 'axios';
import { HttpMethod, ServiceType } from '../models/Request';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getPrefixNumber = async (): Promise<any> => {
    try {
        const response = await axios({
            url: `${baseUrl}/api/auth/notification`,
            headers: { 'Content-Type': 'application/json' },
            method: HttpMethod.POST,
            data: {
                resource: '/notifications/prefix-number',
                method: HttpMethod.GET,
                service: ServiceType.NOTIFICATION,
                data: [],
            },
        });
        return response.data;
    } catch (error) {
        return error;
    }
}